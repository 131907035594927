function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import iMenuHeadTitle from './title';
import { mapState, mapGetters } from 'vuex';
import { getStyle } from 'view-design/src/utils/assist';
import clickItem from '../mixins/click-item';
// import { on, off } from 'view-design/src/utils/dom';
// import { throttle } from 'lodash';

export default {
  name: 'iMenuHead',
  components: {
    iMenuHeadTitle: iMenuHeadTitle
  },
  mixins: [clickItem],
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState('admin/layout', ['isMobile'])), mapState('admin/menu', ['headerName'])), mapGetters('admin/menu', ['filterHeader'])),
  data: function data() {
    return {
      handleResize: function handleResize() {},
      isMenuLimit: false,
      menuMaxWidth: 0 // 达到这个值后，menu 就显示不下了
    };
  },
  methods: {
    handleGetMenuHeight: function handleGetMenuHeight() {
      var menuWidth = parseInt(getStyle(this.$el, 'width'));
      var $menu = this.$refs.menu;
      if ($menu) {
        var menuHeight = parseInt(getStyle(this.$refs.menu.$el, 'height'));
        if (menuHeight > 64) {
          if (!this.isMenuLimit) {
            this.menuMaxWidth = menuWidth;
          }
          this.isMenuLimit = true;
        }
      } else if (menuWidth >= this.menuMaxWidth) {
        this.isMenuLimit = false;
      }
    }
  },
  watch: {
    filterHeader: function filterHeader() {
      this.handleGetMenuHeight();
    },
    isMobile: function isMobile() {
      this.handleGetMenuHeight();
    }
  },
  mounted: function mounted() {
    // this.handleResize = throttle(this.handleGetMenuHeight, 100, { leading: false });
    // on(window, 'resize', this.handleResize);
    this.handleGetMenuHeight();
  },
  beforeDestroy: function beforeDestroy() {
    // off(window, 'resize', this.handleResize);
  }
};