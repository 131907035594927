function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import iMenuSideTitle from './menu-title';
import clickItem from '../mixins/click-item';
import { mapState } from 'vuex';
export default {
  name: 'iMenuSideCollapse',
  components: {
    iMenuSideTitle: iMenuSideTitle
  },
  mixins: [clickItem],
  props: {
    menu: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    // 是否是第一级，区分在于左侧和展开侧
    topLevel: {
      type: Boolean,
      default: false
    }
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState('admin/layout', ['siderTheme', 'showCollapseMenuTitle'])), mapState('admin/menu', ['activePath', 'openNames'])), {}, {
    dropdownClasses: function dropdownClasses() {
      return {
        'i-layout-menu-side-collapse-top': this.topLevel,
        'i-layout-menu-side-collapse-dark': this.siderTheme === 'dark'
      };
    },
    menuItemClasses: function menuItemClasses() {
      return ['ivu-menu-item i-layout-menu-side-collapse-top-item', {
        'ivu-menu-item-selected ivu-menu-item-active': this.openNames.indexOf(this.menu.path) >= 0 // -active 在高亮时，有背景
      }];
    }
  })
};