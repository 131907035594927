function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
var log = {};

/**
 * @description 返回这个样式的颜色值
 * @param {String} type 样式名称 [ primary | success | warning | error | text ]
 */
function typeColor() {
  var type = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'default';
  var color = '';
  switch (type) {
    case 'default':
      color = '#515a6e';
      break;
    case 'primary':
      color = '#2d8cf0';
      break;
    case 'success':
      color = '#19be6b';
      break;
    case 'warning':
      color = '#ff9900';
      break;
    case 'error':
      color = '#ed4014';
      break;
    default:
      ;
      break;
  }
  return color;
}

/**
 * @description 打印一个 [ title | text ] 样式的信息
 * @param {String} title title text
 * @param {String} info info text
 * @param {String} type style
 */
log.capsule = function (title, info) {
  var type = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'primary';
  console.log("%c ".concat(title, " %c ").concat(info, " %c"), 'background:#35495E; padding: 1px; border-radius: 3px 0 0 3px; color: #fff;', "background:".concat(typeColor(type), "; padding: 1px; border-radius: 0 3px 3px 0;  color: #fff;"), 'background:transparent');
};

/**
 * @description 打印彩色文字
 */
log.colorful = function (textArr) {
  var _console;
  (_console = console).log.apply(_console, ["%c".concat(textArr.map(function (t) {
    return t.text || '';
  }).join('%c'))].concat(_toConsumableArray(textArr.map(function (t) {
    return "color: ".concat(typeColor(t.type), ";");
  }))));
};

/**
 * @description 打印 default 样式的文字
 */
log.default = function (text) {
  log.colorful([{
    text: text
  }]);
};

/**
 * @description 打印 primary 样式的文字
 */
log.primary = function (text) {
  log.colorful([{
    text: text,
    type: 'primary'
  }]);
};

/**
 * @description 打印 success 样式的文字
 */
log.success = function (text) {
  log.colorful([{
    text: text,
    type: 'success'
  }]);
};

/**
 * @description 打印 warning 样式的文字
 */
log.warning = function (text) {
  log.colorful([{
    text: text,
    type: 'warning'
  }]);
};

/**
 * @description 打印 error 样式的文字
 */
log.error = function (text) {
  log.colorful([{
    text: text,
    type: 'error'
  }]);
};
export default log;