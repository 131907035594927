function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import store from '@/store';
import axios from 'axios';
import util from '@/libs/util';
import Setting from '@/setting';
import qs from 'qs';
import routes from '@/router/routes';
import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter);
import { Message, Notice } from 'view-design';

// 创建一个错误
function errorCreate(msg) {
  var err = new Error(msg);
  errorLog(err);
  throw err;
}

// 记录和显示错误
function errorLog(err) {
  // 添加到日志
  store.dispatch('admin/log/push', {
    message: '数据请求异常',
    type: 'error',
    meta: {
      error: err
    }
  });
  // 打印到控制台
  if (process.env.NODE_ENV === 'development') {
    util.log.error('>>>>>> Error >>>>>>');
    console.log(err);
  }
  // 显示提示，可配置使用 iView 的 $Message 还是 $Notice 组件来显示
  if (Setting.errorModalType === 'Message') {
    Message.error({
      content: err.message,
      duration: Setting.modalDuration
    });
  } else if (Setting.errorModalType === 'Notice') {
    Notice.error({
      title: '提示',
      desc: err.message,
      duration: Setting.modalDuration
    });
  }
}

// 创建一个 axios 实例
var service = axios.create({
  baseURL: Setting.apiBaseURL,
  timeout: 300000 // 请求超时时间
});

// 请求拦截器
service.interceptors.request.use(function (config) {
  // 在请求发送之前做一些处理
  // const token = util.cookies.get('token');
  // 让每个请求携带token-- ['X-Token']为自定义key 请根据实际情况自行修改
  // config.headers['X-Token'] = token;
  var data = config.data,
    method = config.method;
  if (config.url === '/erp/login') {
    data = qs.stringify(data);
  }
  return _objectSpread(_objectSpread({}, config), {}, {
    // headers: {
    //     'Content-Type': method === 'post' ? 'application/x-www-form-urlencoded;CharSet=UTF-8' : '',
    // },
    //data: qs.stringify(data),
    data: data
  });
  return config;
}, function (error) {
  // 发送失败
  console.log(error);
  Promise.reject(error);
});

// 响应拦截器
service.interceptors.response.use(function (response) {
  // dataAxios 是 axios 返回数据中的 data
  var dataAxios = response.data;
  // 这个状态码是和后端约定的
  var code = dataAxios.code;
  // 根据 code 进行判断
  if (code === undefined) {
    // 如果没有 code 代表这不是项目后端开发的接口
    return dataAxios;
  } else {
    // 有 code 代表这是一个后端接口 可以进行进一步的判断
    var router = new VueRouter({
      routes: routes
    });
    switch (code) {
      case 1:
        return dataAxios.data;
      case 0:
        return dataAxios;
      case 313:
      case 314:
        //登录
        router.replace('/login').then(function () {
          window.location.reload();
        });
        errorCreate("".concat(dataAxios.message));
        return;
      default:
        // 不是正确的 code
        errorCreate("".concat(dataAxios.message, ": ").concat(response.config.url));
        break;
    }
  }
}, function (error) {
  if (error && error.response) {
    switch (error.response.status) {
      case 400:
        error.message = '请求错误';
        break;
      case 401:
        error.message = '未授权，请登录';
        break;
      case 403:
        error.message = '拒绝访问';
        break;
      case 404:
        error.message = "\u8BF7\u6C42\u5730\u5740\u51FA\u9519: ".concat(error.response.config.url);
        break;
      case 408:
        error.message = '请求超时';
        break;
      case 500:
        error.message = '服务器内部错误';
        break;
      case 501:
        error.message = '服务未实现';
        break;
      case 502:
        error.message = '网关错误';
        break;
      case 503:
        error.message = '服务不可用';
        break;
      case 504:
        error.message = '网关超时';
        break;
      case 505:
        error.message = 'HTTP版本不受支持';
        break;
      default:
        break;
    }
  }
  errorLog(error);
  return Promise.reject(error);
});
export default service;