import exception from './modules/exception';
import setting from './modules/setting';
import system from './modules/system';
import BasicLayout from '@/layouts/basic-layout';
import wj from "./modules/wj";
import wechat from "./modules/wechat";
import activity from "./modules/activity";
import base from "./modules/base";
import goods from "./modules/goods";
import custom from "./modules/custom";
import evaluate from "./modules/evaluate";
import website from "./modules/website";
import cadmin from "./modules/cadmin";
import letter from "@/router/modules/letter";
import capital from "@/router/modules/capital";

/**
 * 在主框架内显示
 */

var frameIn = [{
  path: '/',
  component: BasicLayout,
  redirect: {
    name: 'setting-account'
  },
  children: [{
    path: 'index',
    name: 'index',
    redirect: {
      name: 'setting-account'
    }
  },
  // 刷新页面 必须保留
  {
    path: 'refresh',
    name: 'refresh',
    hidden: true,
    component: {
      beforeRouteEnter: function beforeRouteEnter(to, from, next) {
        next(function (instance) {
          return instance.$router.replace(from.fullPath);
        });
      },
      render: function render(h) {
        return h();
      }
    }
  },
  // 页面重定向 必须保留
  {
    path: 'redirect/:route*',
    name: 'redirect',
    hidden: true,
    component: {
      beforeRouteEnter: function beforeRouteEnter(to, from, next) {
        next(function (instance) {
          return instance.$router.replace(JSON.parse(from.params.route));
        });
      },
      render: function render(h) {
        return h();
      }
    }
  }]
}, exception, setting, system, wj, wechat, activity, base, goods, custom, evaluate, website, cadmin, letter, capital];

/**
 * 在主框架之外显示
 */

var frameOut = [
// 登录
{
  path: '/login',
  name: 'login',
  meta: {
    title: '$t:page.login.title'
  },
  component: function component() {
    return import('@/pages/account/login');
  }
}, {
  path: '/test',
  name: 'test',
  meta: {
    title: '$t:page.login.title'
  },
  component: function component() {
    return import('@/pages/test');
  }
}, {
  path: '/test1',
  name: 'test1',
  meta: {
    title: '$t:page.login.title'
  },
  component: function component() {
    return import('@/pages/test1');
  }
}, {
  path: '/website/dataea',
  name: 'dataea',
  meta: {
    title: '官网统计',
    auth: true
  },
  component: function component() {
    return import('@/pages/website/dataea');
  }
}];
var dashboard = [
// dashboard
{
  path: '/dashboard/index',
  name: 'dashboard',
  meta: {
    auth: true,
    title: '数据报表'
  },
  component: function component() {
    return import('@/pages/dashboard/index');
  }
}];
/**
 * 错误页面
 */

var errorPage = [{
  path: '/403',
  name: '403',
  meta: {
    title: '403'
  },
  component: function component() {
    return import('@/pages/exception/403');
  }
}, {
  path: '/500',
  name: '500',
  meta: {
    title: '500'
  },
  component: function component() {
    return import('@/pages/exception/500');
  }
}, {
  path: '*',
  name: '404',
  meta: {
    title: '404'
  },
  component: function component() {
    return import('@/pages/exception/404');
  }
}];

// 导出需要显示菜单的
export var frameInRoutes = frameIn;

// 重新组织后导出
export default [].concat(frameIn, frameOut, errorPage, dashboard);