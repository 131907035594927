function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState } from 'vuex';
import { flattenSiderMenu } from '@/libs/system';
import iMenuHeadTitle from '../menu-head/title';
import { on, off } from 'view-design/src/utils/dom';
import { findComponentUpward, getStyle } from 'view-design/src/utils/assist';
import { throttle } from 'lodash';
export default {
  name: 'iHeaderBreadcrumb',
  components: {
    iMenuHeadTitle: iMenuHeadTitle
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState('admin/layout', ['showBreadcrumbIcon', 'menuCollapse'])), mapState('admin/menu', ['openNames', 'activePath', 'header', 'headerName', 'menuSider'])), {}, {
    siderMenuObject: function siderMenuObject() {
      var obj = {};
      this.allSiderMenu.forEach(function (item) {
        if ('path' in item) {
          obj[item.path] = item;
        }
      });
      return obj;
    },
    items: function items() {
      var _this = this;
      var items = _toConsumableArray(this.openNames);
      var newItems = [];
      items.forEach(function (i) {
        newItems.push(_this.siderMenuObject[i]);
      });
      return newItems;
    },
    // 第一级，默认是 menu/header.js 中的第一项
    topItem: function topItem() {
      var _this2 = this;
      return this.header.find(function (item) {
        return item.name === _this2.headerName;
      });
    },
    // 得到所有侧边菜单，并转为平级，查询图标及显示对应内容
    allSiderMenu: function allSiderMenu() {
      return flattenSiderMenu(this.menuSider, []);
    }
  }),
  data: function data() {
    return {
      handleResize: function handleResize() {},
      isLimit: false,
      maxWidth: 560,
      breadcrumbWidth: 0
    };
  },
  methods: {
    handleCheckWidth: function handleCheckWidth() {
      var _this3 = this;
      var $header = findComponentUpward(this, 'Header');
      if ($header) {
        var headerWidth = parseInt(getStyle($header.$el, 'width'));
        this.$nextTick(function () {
          _this3.isLimit = headerWidth - _this3.maxWidth <= _this3.breadcrumbWidth;
        });
      }
    },
    handleGetWidth: function handleGetWidth() {
      var _this4 = this;
      this.isLimit = false;
      this.$nextTick(function () {
        var $breadcrumb = _this4.$refs.breadcrumb;
        if ($breadcrumb) {
          _this4.breadcrumbWidth = parseInt(getStyle($breadcrumb.$el, 'width'));
        }
      });
    }
  },
  watch: {
    topItem: {
      handler: function handler() {
        this.handleGetWidth();
        this.handleCheckWidth();
      },
      deep: true
    },
    items: {
      handler: function handler() {
        this.handleGetWidth();
        this.handleCheckWidth();
      },
      deep: true
    },
    activePath: {
      handler: function handler() {
        this.handleGetWidth();
        this.handleCheckWidth();
      },
      deep: true
    }
  },
  mounted: function mounted() {
    this.handleResize = throttle(this.handleCheckWidth, 100, {
      leading: false
    });
    on(window, 'resize', this.handleResize);
    this.handleGetWidth();
    this.handleCheckWidth();
  },
  beforeDestroy: function beforeDestroy() {
    off(window, 'resize', this.handleResize);
  }
};