var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("span", {
    staticClass: "i-layout-header-trigger i-layout-header-trigger-min i-layout-header-trigger-in"
  }, [_c("Notification", {
    staticClass: "i-layout-header-notice",
    class: {
      "i-layout-header-notice-mobile": _vm.isMobile
    },
    attrs: {
      wide: _vm.isMobile,
      "badge-props": _vm.badgeProps,
      "auto-count": ""
    },
    on: {
      "on-load-more": _vm.handleLoadMore,
      "on-clear": _vm.handleClear
    }
  }, [_c("Icon", {
    attrs: {
      slot: "icon",
      custom: "i-icon i-icon-notification"
    },
    slot: "icon"
  }), _c("NotificationTab", {
    attrs: {
      title: "通知",
      name: "message",
      count: _vm.unreadMessage,
      "loaded-all": _vm.messageList.length >= 15,
      loading: _vm.messageLoading,
      "scroll-to-load": false
    }
  }, _vm._l(_vm.messageList, function (item, index) {
    return _c("NotificationItem", {
      key: index,
      attrs: {
        title: item.title,
        icon: item.icon,
        "icon-color": item.iconColor,
        time: item.time,
        read: item.read
      }
    });
  }), 1), _c("NotificationTab", {
    attrs: {
      title: "关注",
      name: "follow",
      count: _vm.unreadFollow,
      "loaded-all": _vm.followList.length >= 15,
      loading: _vm.followLoading,
      "scroll-to-load": false
    }
  }, _vm._l(_vm.followList, function (item, index) {
    return _c("NotificationItem", {
      key: index,
      attrs: {
        avatar: item.avatar,
        title: item.title,
        time: item.time,
        read: item.read
      }
    });
  }), 1), _c("NotificationTab", {
    attrs: {
      title: "待办",
      name: "todo",
      count: _vm.unreadTodo,
      "loaded-all": _vm.todoList.length >= 15,
      loading: _vm.todoLoading,
      "scroll-to-load": false
    }
  }, _vm._l(_vm.todoList, function (item, index) {
    return _c("NotificationItem", {
      key: index,
      attrs: {
        title: item.title,
        content: item.content,
        tag: item.tag,
        "tag-props": item.tagProps,
        read: item.read
      }
    });
  }), 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };