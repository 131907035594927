function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import iMenuHead from './menu-head';
import iMenuSide from './menu-side';
import iHeaderLogo from './header-logo';
import iHeaderCollapse from './header-collapse';
import iHeaderReload from './header-reload';
import iHeaderBreadcrumb from './header-breadcrumb';
import iHeaderSearch from './header-search';
import iHeaderLog from './header-log';
import iHeaderFullscreen from './header-fullscreen';
import iHeaderNotice from './header-notice';
import iHeaderUser from './header-user';
import iHeaderI18n from './header-i18n';
import iHeaderSetting from './header-setting';
import iTabs from './tabs';
import iCopyright from '@/components/copyright';
import { mapState, mapGetters, mapMutations } from 'vuex';
import Setting from '@/setting';
import { requestAnimation } from '@/libs/util';
export default {
  name: 'BasicLayout',
  components: {
    iMenuHead: iMenuHead,
    iMenuSide: iMenuSide,
    iCopyright: iCopyright,
    iHeaderLogo: iHeaderLogo,
    iHeaderCollapse: iHeaderCollapse,
    iHeaderReload: iHeaderReload,
    iHeaderBreadcrumb: iHeaderBreadcrumb,
    iHeaderSearch: iHeaderSearch,
    iHeaderUser: iHeaderUser,
    iHeaderI18n: iHeaderI18n,
    iHeaderLog: iHeaderLog,
    iHeaderFullscreen: iHeaderFullscreen,
    iHeaderSetting: iHeaderSetting,
    iHeaderNotice: iHeaderNotice,
    iTabs: iTabs
  },
  data: function data() {
    return {
      showDrawer: false,
      ticking: false,
      headerVisible: true,
      oldScrollTop: 0,
      isDelayHideSider: false,
      // hack，当从隐藏侧边栏的 header 切换到正常 header 时，防止 Logo 抖动
      loadRouter: true
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState('admin/layout', ['siderTheme', 'headerTheme', 'headerStick', 'tabs', 'tabsFix', 'siderFix', 'headerFix', 'headerHide', 'headerMenu', 'isMobile', 'isTablet', 'isDesktop', 'menuCollapse', 'showMobileLogo', 'showSearch', 'showNotice', 'showFullscreen', 'showSiderCollapse', 'showBreadcrumb', 'showLog', 'showI18n', 'showReload', 'enableSetting'])), mapState('admin/page', ['keepAlive'])), mapGetters('admin/menu', ['hideSider'])), {}, {
    // 如果开启 headerMenu，且当前 header 的 hideSider 为 true，则将顶部按 headerStick 处理
    // 这时，即使没有开启 headerStick，仍然按开启处理
    isHeaderStick: function isHeaderStick() {
      var state = this.headerStick;
      if (this.hideSider) state = true;
      return state;
    },
    showHeader: function showHeader() {
      var visible = true;
      if (this.headerFix && this.headerHide && !this.headerVisible) visible = false;
      return visible;
    },
    headerClasses: function headerClasses() {
      return ["i-layout-header-color-".concat(this.headerTheme), {
        'i-layout-header-fix': this.headerFix,
        'i-layout-header-fix-collapse': this.headerFix && this.menuCollapse,
        'i-layout-header-mobile': this.isMobile,
        'i-layout-header-stick': this.isHeaderStick && !this.isMobile,
        'i-layout-header-with-menu': this.headerMenu,
        'i-layout-header-with-hide-sider': this.hideSider || this.isDelayHideSider
      }];
    },
    headerStyle: function headerStyle() {
      var menuWidth = this.isHeaderStick ? 0 : this.menuCollapse ? 80 : Setting.menuSideWidth;
      return this.isMobile || !this.headerFix ? {} : {
        width: "calc(100% - ".concat(menuWidth, "px)")
      };
    },
    siderClasses: function siderClasses() {
      return {
        'i-layout-sider-fix': this.siderFix,
        'i-layout-sider-dark': this.siderTheme === 'dark'
      };
    },
    contentClasses: function contentClasses() {
      return {
        'i-layout-content-fix-with-header': this.headerFix,
        'i-layout-content-with-tabs': this.tabs,
        'i-layout-content-with-tabs-fix': this.tabs && this.tabsFix
      };
    },
    insideClasses: function insideClasses() {
      return {
        'i-layout-inside-fix-with-sider': this.siderFix,
        'i-layout-inside-fix-with-sider-collapse': this.siderFix && this.menuCollapse,
        'i-layout-inside-with-hide-sider': this.hideSider,
        'i-layout-inside-mobile': this.isMobile
      };
    },
    drawerClasses: function drawerClasses() {
      var className = 'i-layout-drawer';
      if (this.siderTheme === 'dark') className += ' i-layout-drawer-dark';
      return className;
    },
    menuSideWidth: function menuSideWidth() {
      return this.menuCollapse ? 80 : Setting.menuSideWidth;
    }
  }),
  watch: {
    hideSider: function hideSider() {
      var _this = this;
      this.isDelayHideSider = true;
      setTimeout(function () {
        _this.isDelayHideSider = false;
      }, 0);
    },
    '$route': function $route(to, from) {
      if (to.name === from.name) {
        // 相同路由，不同参数，跳转时，重载页面
        if (Setting.sameRouteForceUpdate) {
          this.handleReload();
        }
      }
    }
  },
  methods: _objectSpread(_objectSpread(_objectSpread({}, mapMutations('admin/layout', ['updateMenuCollapse'])), mapMutations('admin/page', ['keepAlivePush', 'keepAliveRemove'])), {}, {
    handleToggleDrawer: function handleToggleDrawer(state) {
      if (typeof state === 'boolean') {
        this.showDrawer = state;
      } else {
        this.showDrawer = !this.showDrawer;
      }
    },
    handleScroll: function handleScroll() {
      var _this2 = this;
      if (!this.headerHide) return;
      var scrollTop = document.body.scrollTop + document.documentElement.scrollTop;
      if (!this.ticking) {
        this.ticking = true;
        requestAnimation(function () {
          if (_this2.oldScrollTop > scrollTop) {
            _this2.headerVisible = true;
          } else if (scrollTop > 300 && _this2.headerVisible) {
            _this2.headerVisible = false;
          } else if (scrollTop < 300 && !_this2.headerVisible) {
            _this2.headerVisible = true;
          }
          _this2.oldScrollTop = scrollTop;
          _this2.ticking = false;
        });
      }
    },
    handleHeaderWidthChange: function handleHeaderWidthChange() {
      var $breadcrumb = this.$refs.breadcrumb;
      if ($breadcrumb) {
        $breadcrumb.handleGetWidth();
        $breadcrumb.handleCheckWidth();
      }
      var $menuHead = this.$refs.menuHead;
      if ($menuHead) {
        // todo $menuHead.handleGetMenuHeight();
      }
    },
    handleReload: function handleReload() {
      var _this3 = this;
      // 针对缓存的页面也生效
      var isCurrentPageCache = this.keepAlive.indexOf(this.$route.name) > -1;
      var pageName = this.$route.name;
      if (isCurrentPageCache) {
        this.keepAliveRemove(pageName);
      }
      this.loadRouter = false;
      this.$nextTick(function () {
        _this3.loadRouter = true;
        if (isCurrentPageCache) {
          _this3.keepAlivePush(pageName);
        }
      });
    }
  }),
  mounted: function mounted() {
    document.addEventListener('scroll', this.handleScroll, {
      passive: true
    });
  },
  beforeDestroy: function beforeDestroy() {
    document.removeEventListener('scroll', this.handleScroll);
  },
  created: function created() {
    if (this.isTablet && this.showSiderCollapse) this.updateMenuCollapse(true);
  }
};