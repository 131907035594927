function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { mapState } from 'vuex';
export default {
  name: 'iHeaderNotice',
  data: function data() {
    return {
      badgeProps: {
        offset: [20, 0]
      },
      messageBaseList: [{
        icon: 'md-mail',
        iconColor: '#3391e5',
        title: '蒂姆·库克回复了你的邮件',
        read: 1,
        time: 1557297198
      }, {
        icon: 'md-home',
        iconColor: '#87d068',
        title: '乔纳森·伊夫邀请你参加会议',
        read: 0,
        time: 1557297198
      }, {
        icon: 'md-information',
        iconColor: '#fe5c57',
        title: '斯蒂夫·沃兹尼亚克已批准了你的休假申请',
        read: 1,
        time: 1557297198
      }, {
        icon: 'md-star',
        iconColor: '#ff9900',
        title: '史蒂夫·乔布斯收藏了你的文章',
        read: 1,
        time: 1557297198
      }, {
        icon: 'md-people',
        iconColor: '#f06292',
        title: '比尔·费尔南德斯通过了你的好友申请',
        read: 1,
        time: 1557297198
      }],
      followBaseList: [{
        avatar: 'https://dev-file.iviewui.com/BbnuuEiM0QXNPHVCvb3E2AFrawIjCkqW/avatar',
        title: '史蒂夫·乔布斯 关注了你',
        read: 1,
        time: 1557299299
      }, {
        avatar: 'https://dev-file.iviewui.com/zhj85zgAfEjChCNIKT1LQENUIOyOYCaX/avatar',
        title: '斯蒂夫·沃兹尼亚克 关注了你',
        read: 1,
        time: 1557299299
      }, {
        avatar: 'https://dev-file.iviewui.com/TkH54UozsINlex15TAMI00GElsfsKSiC/avatar',
        title: '乔纳森·伊夫 关注了你',
        read: 0,
        time: 1557299299
      }, {
        avatar: 'https://dev-file.iviewui.com/xrzbBR99F6tYsDJPLNrvwhllowbuL7Gw/avatar',
        title: '蒂姆·库克 关注了你',
        read: 1,
        time: 1557299299
      }, {
        avatar: 'https://dev-file.iviewui.com/bgrngoUb9A6UQ2kAwBFtnSNzhrh2qj1O/avatar',
        title: '比尔·费尔南德斯 关注了你',
        read: 1,
        time: 1557299299
      }],
      todoBaseList: [{
        title: '2019 下半年 OKR',
        content: '需要在 2019-06-14 之前完成',
        tag: '未开始',
        tagProps: {
          color: 'default'
        },
        read: 1
      }, {
        title: '公孙离的面试评审',
        content: '需要在 2019-06-14 之前完成',
        tag: '即将到期',
        tagProps: {
          color: 'red'
        },
        read: 0
      }, {
        title: 'iView 三周年生日会',
        content: '需要在 2019-07-28 之前完成',
        tag: '进行中',
        tagProps: {
          color: 'blue'
        },
        read: 1
      }, {
        title: 'iView 三周年生日会',
        content: '需要在 2019-07-28 之前完成',
        tag: '进行中',
        tagProps: {
          color: 'blue'
        },
        read: 1
      }],
      messageList: [],
      followList: [],
      todoList: [],
      messageLoading: false,
      followLoading: false,
      todoLoading: false
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState('admin/layout', ['isMobile'])), {}, {
    unreadMessage: function unreadMessage() {
      var unread = 0;
      this.messageList.forEach(function (item) {
        if (!item.read) unread++;
      });
      return unread;
    },
    unreadFollow: function unreadFollow() {
      var unread = 0;
      this.followList.forEach(function (item) {
        if (!item.read) unread++;
      });
      return unread;
    },
    unreadTodo: function unreadTodo() {
      var unread = 0;
      this.todoList.forEach(function (item) {
        if (!item.read) unread++;
      });
      return unread;
    }
  }),
  methods: {
    handleLoadMore: function handleLoadMore(tab) {
      this.loadMore(tab.name);
    },
    loadMore: function loadMore(type) {
      var _this = this;
      if (this["".concat(type, "Loading")]) return;
      this["".concat(type, "Loading")] = true;
      setTimeout(function () {
        _this["".concat(type, "List")] = _this["".concat(type, "List")].concat(_toConsumableArray(_this["".concat(type, "BaseList")]));
        _this["".concat(type, "Loading")] = false;
      }, 1000);
    },
    handleClear: function handleClear(tab) {
      this.clearUnread(tab.name);
    },
    clearUnread: function clearUnread(type) {
      this["".concat(type, "List")] = this["".concat(type, "List")].map(function (item) {
        item.read = 1;
        return item;
      });
    }
  },
  mounted: function mounted() {
    this.messageList = _toConsumableArray(this.messageBaseList);
    this.followList = _toConsumableArray(this.followBaseList);
    this.todoList = _toConsumableArray(this.todoBaseList);
  }
};