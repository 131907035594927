function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import BasicLayout from '@/layouts/basic-layout';
var meta = {
  auth: true
};
var pre = 'capital-';
export default {
  path: '/capital',
  name: 'capital',
  meta: meta,
  component: BasicLayout,
  children: [{
    path: 'bank',
    name: "".concat(pre, "bank"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '银行流水'
    }),
    component: function component() {
      return import('@/pages/capital-pool/bank/list');
    }
  }, {
    path: 'unionPay',
    name: "".concat(pre, "unionPay"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '银联流水'
    }),
    component: function component() {
      return import('@/pages/capital-pool/unionPay/list');
    }
  }, {
    path: 'index',
    name: "".concat(pre, "index"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '资金池'
    }),
    component: function component() {
      return import('@/pages/capital-pool/index');
    }
  }]
};