var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("Dropdown", {
    class: _vm.dropdownClasses,
    attrs: {
      placement: "right-start"
    }
  }, [_vm.topLevel ? _c("li", {
    class: _vm.menuItemClasses
  }, [_c("i-menu-side-title", {
    attrs: {
      menu: _vm.menu,
      "hide-title": "",
      collapse: ""
    }
  })], 1) : _c("DropdownItem", [_c("i-menu-side-title", {
    attrs: {
      menu: _vm.menu,
      selected: _vm.openNames.indexOf(_vm.menu.path) >= 0,
      collapse: ""
    }
  }), _c("Icon", {
    staticClass: "i-layout-menu-side-arrow",
    attrs: {
      type: "ios-arrow-forward"
    }
  })], 1), _c("DropdownMenu", {
    attrs: {
      slot: "list"
    },
    slot: "list"
  }, [_vm.showCollapseMenuTitle ? _c("div", {
    staticClass: "i-layout-menu-side-collapse-title"
  }, [_c("i-menu-side-title", {
    attrs: {
      menu: _vm.menu,
      collapse: ""
    }
  })], 1) : _vm._e(), _vm._l(_vm.menu.children, function (item, index) {
    return [item.children === undefined || !item.children.length ? _c("i-link", {
      key: index,
      attrs: {
        to: item.path,
        target: item.target
      },
      nativeOn: {
        click: function click($event) {
          return _vm.handleClick(item.path);
        }
      }
    }, [_c("DropdownItem", {
      class: {
        "i-layout-menu-side-collapse-item-selected": item.path === _vm.activePath
      },
      attrs: {
        divided: item.divided
      }
    }, [_c("i-menu-side-title", {
      attrs: {
        menu: item,
        collapse: ""
      }
    })], 1)], 1) : _c("i-menu-side-collapse", {
      key: index,
      attrs: {
        menu: item
      }
    })];
  })], 2)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };